import { VStack } from '@chakra-ui/react';
import { Button, InputVariant, Radio } from '@pluxee-design-system/core';
import { useContractDetail } from 'api/contractApi';
import ProductCard from 'common/cards/ProductCard';
import {
  AddressControl,
  CheckboxControl,
  CheckboxGroupControl,
  DateControl,
  InputControl,
  PhoneNumberControl,
  RadioGroupControl,
  SelectControl,
  SubmitButton,
} from 'common/forms';
import { SectionTitle } from 'common/typography';
import { getChildren } from 'components/Form/ActivityTree/utils';
import {
  findTerminalProviderByTID,
  TERMINAL_DELAY_END,
  TERMINAL_DELAY_START,
} from 'components/Onboarding/utils';
import ProductCheckbox, { ProductCheckboxItem } from 'components/Products/ProductCheckbox';
import { beTerminalsSchema, createSchema, getValidationErrors } from 'config';
import { useFormikContext } from 'formik';
import { LocationTypeEnum, ProductStatusEnum } from 'generated/models';
import useTranslations from 'i18n';
import { isEqual, pick } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import gtm, { gtmUtils } from 'trackers/gtm';
import { BelgiumLocationConfig } from 'types/config';
import insertIf from 'utils/insertIf';
import maskString from 'utils/maskString';
import {
  BelgiumOnboardingValues,
  BelgiumValidationContext,
  PaymentOptionEnum,
  PaymentTypeCodeEnum,
  POSValues,
} from '../types';
import OnlineTerminal from './BeOnlineTerminal';
import BePaymentOption, { PaymentOptionItem } from './BePaymentOption';
import Terminals from './BeTerminals';
import { LocationStepType } from './types';

const LocationStep: LocationStepType<BelgiumLocationConfig> = ({
  activities = [],
  contractId,
  isSkippable,
  isOnboarding = true,
  isPaymentEnabled,
  config: rawConfig,
  contractProducts = [],
  steps,
  terminalProviders,
  toStep,
}) => {
  const { t } = useTranslations();
  const lastPosChange = useRef<POSValues[]>([]);
  const config = (rawConfig as BelgiumLocationConfig).form;
  const { handleChange, values, setFieldValue, setValues } =
    useFormikContext<BelgiumOnboardingValues>();
  const { data: contractDetails } = useContractDetail(isSkippable ? undefined : contractId);
  const contract = contractDetails?.contract;

  // codebooks
  const locationTypes = useMemo(
    () =>
      [
        {
          name: t(
            'location_add.sectionTexts.businessInformation.physicalPos',
            'Physical point of sale',
          ),
          key: LocationTypeEnum.Physical,
        },
        {
          name: t('location_add.sectionTexts.businessInformation.onlinePos', 'Webshop'),
          key: LocationTypeEnum.Online,
        },
        {
          name: t('location_add.sectionTexts.businessInformation.marketplacePos', 'Marketplace'),
          key: LocationTypeEnum.Marketplace,
        },
      ].filter((locationType) => isPaymentEnabled!(null, null, locationType.key)),
    [isPaymentEnabled, t],
  );
  const products = useMemo(
    () =>
      contractProducts
        .map<ProductCheckboxItem>(
          ({ payout, product, product: { code, name, nameKey }, status }) => ({
            value: code,
            locationType: values.type || undefined,
            isDisabled: isOnboarding
              ? false
              : status !== undefined && status !== ProductStatusEnum.Active,
            label: t('product_details.products.*.product.name', name, {
              query: { where: 'product_details.products.*.product.code', is: nameKey },
            }),
            payout,
            product,
            status: isOnboarding ? undefined : status,
          }),
        )
        .filter((product) => isPaymentEnabled!(null, [product.value], values.type)),
    [contractProducts, isOnboarding, isPaymentEnabled, values.type, t],
  );
  const hasPayment = useCallback(
    (code: PaymentTypeCodeEnum) =>
      isPaymentEnabled!(
        code,
        products.map((p) => p.value),
        values.type,
      ),
    [isPaymentEnabled, products, values.type],
  );
  const isPaymentDisabled = useCallback(
    (code: PaymentTypeCodeEnum) => !isPaymentEnabled!(code, values.products, values.type),
    [isPaymentEnabled, values.type, values.products],
  );

  const paymentOptions = useMemo<PaymentOptionItem[]>(
    () => [
      ...insertIf(hasPayment(PaymentTypeCodeEnum.Marketplace), {
        value: PaymentOptionEnum.Marketplace,
        label: t('location_add.sectionTexts.paymentOptions.marketplaces.header', 'Marketplaces'),
        description: t(
          'location_add.sectionTexts.paymentOptions.marketplaces.description',
          'Available marketplaces',
        ),
        isDisabled: isPaymentDisabled(PaymentTypeCodeEnum.Marketplace),
      }),
      ...insertIf(hasPayment(PaymentTypeCodeEnum.Payconiq), {
        value: PaymentOptionEnum.Payconiq,
        label: t('location_add.section_texts.paymentOptions.payconiq.header', 'Payconiq'),
        description: t(
          'location_add.section_texts.paymentOptions.payconiq.description',
          'Payconiq',
        ),
        isDisabled: isPaymentDisabled(PaymentTypeCodeEnum.Payconiq),
      }),
      ...insertIf(hasPayment(PaymentTypeCodeEnum.Terminal), {
        value: PaymentOptionEnum.POS,
        label: t(
          'location_add.section_texts.paymentOptions.paymentTerminal.header',
          'Payment terminal',
        ),
        description: t(
          'location_add.section_texts.paymentOptions.paymentTerminal.description',
          'Payment terminal',
        ),
        isDisabled: isPaymentDisabled(PaymentTypeCodeEnum.Terminal),
        content: (
          <Terminals
            config={config}
            isOnboarding={isOnboarding}
            terminalProviders={terminalProviders!}
          />
        ),
      }),
      ...insertIf(hasPayment(PaymentTypeCodeEnum.PSP), {
        value: PaymentOptionEnum.PSP,
        label: t(
          'location_add.sectionTexts.paymentOptions.onlinePaymentTerminal.header',
          'Payment service provider',
        ),
        description: t(
          'location_add.sectionTexts.paymentOptions.onlinePaymentTerminal.description',
          'Payment service provider',
        ),
        isDisabled: isPaymentDisabled(PaymentTypeCodeEnum.PSP),
        content: (
          <OnlineTerminal config={config} disabled={isPaymentDisabled(PaymentTypeCodeEnum.PSP)} />
        ),
      }),
    ],
    [config, hasPayment, isOnboarding, isPaymentDisabled, terminalProviders, t],
  );
  const activitiesNoParent = useMemo(() => getChildren(activities), [activities]);
  const prefilledCompanyInfo = useMemo(() => {
    const company = contract?.company;
    const address = company?.addrHeadquarters;
    return {
      ...(config.locationName.visible ? { locationName: company?.nameCommercial || '' } : {}),
      ...(config.streetName.visible ? { streetName: address?.street || '' } : {}),
      ...(config.houseNumber.visible ? { houseNumber: address?.houseNumber || '' } : {}),
      ...(config.city.visible ? { city: address?.city || '' } : {}),
      ...(config.box.visible ? { box: address?.box || '' } : {}),
      ...(config.postalCode.visible
        ? {
            postalCode: maskString(address?.zip || '', config.postalCode.format),
          }
        : {}),
      ...(config.accountNumber.visible
        ? { accountNumber: contract?.bankAccount?.accountIban || '' }
        : {}),
      ...(config.typeOfActivity.visible &&
      activitiesNoParent.some((a) => a.value === contract?.services?.[0])
        ? { typeOfActivity: contract?.services?.[0] || '' }
        : {}),
    };
  }, [activitiesNoParent, config, contract]);

  const handleSameCompanyInfo = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setValues((prevValues) => ({
          ...prevValues,
          ...prefilledCompanyInfo,
          sameCompanyInfo: true,
        }));
      } else {
        handleChange(e);
      }
    },
    [handleChange, prefilledCompanyInfo, setValues],
  );
  const trackSelectLocationType = useCallback((value: string) => {
    gtm.trackClickLocationSelectType(value);
  }, []);
  const trackSubmit = useCallback(() => {
    const findPaymentOption = (o: PaymentOptionEnum) => {
      if (values.paymentOptions.includes(o))
        return paymentOptions.find((po) => po.value === o && !po.disabled);
      return undefined;
    };

    const marketplace = findPaymentOption(PaymentOptionEnum.Marketplace);
    const payconiq = findPaymentOption(PaymentOptionEnum.Payconiq);
    const pos = findPaymentOption(PaymentOptionEnum.POS);
    const psp = findPaymentOption(PaymentOptionEnum.PSP);

    gtm.trackClickLocationConfirm({
      event_location_name: values.locationName,
      event_activity: gtmUtils.getSelectedOptions('typeOfActivity'),
      event_accepted_product: values.products.reduce((result, curr) => {
        const productName = products.find((p) => p.value === curr)?.label;
        if (productName) result.push(productName);
        return result;
      }, [] as string[]),
      event_payment_option: [
        ...insertIf(marketplace, { name: marketplace?.label }),
        ...insertIf(payconiq, { name: payconiq?.label }),
        ...insertIf(pos, {
          name: pos?.label,
          options: values.POS.map((pos) => [pos.terminalId, pos.terminalProvider]),
        }),
        ...insertIf(psp, {
          name: psp?.label,
          options: [[values.terminalIdOnline, values.terminalProviderOnline]],
        }),
      ],
      event_terminal_created_number: pos ? values.POS.length : undefined,
    });
  }, [
    paymentOptions,
    products,
    values.locationName,
    values.paymentOptions,
    values.products,
    values.POS,
    values.terminalIdOnline,
    values.terminalProviderOnline,
  ]);

  useEffect(() => {
    const isCompanyInfoPrefilled = isEqual(
      pick(values, Object.keys(prefilledCompanyInfo)),
      prefilledCompanyInfo,
    );
    if (values.sameCompanyInfo && !isCompanyInfoPrefilled) {
      setFieldValue('sameCompanyInfo', false, false);
    } else if (!values.sameCompanyInfo && isCompanyInfoPrefilled) {
      // setFieldValue('sameCompanyInfo', true, false); // to auto tick the checkbox
    }
  }, [prefilledCompanyInfo, setFieldValue, values]);

  useEffect(() => {
    if (!isEqual(values.POS, lastPosChange.current)) {
      const hasOption = values.paymentOptions.includes(PaymentOptionEnum.POS);
      if (
        !hasOption &&
        values.POS.length > 0 &&
        values.POS.length >= lastPosChange.current.length
      ) {
        setValues(
          (p) => ({ ...p, paymentOptions: [...p.paymentOptions, PaymentOptionEnum.POS] }),
          false,
        );
      } else if (hasOption && values.POS.length === 0) {
        setValues(
          (p) => ({
            ...p,
            paymentOptions: p.paymentOptions.filter((o) => o !== PaymentOptionEnum.POS),
          }),
          false,
        );
      }
    }
    lastPosChange.current = values.POS;
  }, [values.paymentOptions, values.POS, setValues]);

  return (
    <>
      <VStack align="stretch" spacing={4}>
        <SectionTitle
          title={t('location_add.sectionTexts.businessInformation.header', 'Business information')}
        />
        <RadioGroupControl
          direction={{ base: 'column', md: 'row' }}
          name="type"
          onAfterChange={isOnboarding ? trackSelectLocationType : undefined}
        >
          {locationTypes.map((locationType) => (
            <ProductCard
              key={locationType.key}
              variant={values.type === locationType.key ? 'selected' : InputVariant.FILLED}
            >
              <Radio value={locationType.key}>{locationType.name}</Radio>
            </ProductCard>
          ))}
        </RadioGroupControl>
        {values.type !== '' && (
          <>
            {!isSkippable && isOnboarding && (
              <CheckboxControl
                name="sameCompanyInfo"
                id="LocationStep_sameCompanyInfo"
                onChange={handleSameCompanyInfo}
              >
                {values.type === LocationTypeEnum.Physical
                  ? t(
                      'location_add.sectionTexts.businessInformation.useCompanyInfoPhysicalPos',
                      'Use the same information from the company (Address, IBAN, ...)',
                    )
                  : t(
                      'location_add.sectionTexts.businessInformation.useCompanyInfoOnlinePos',
                      'Use the same IBAN from the company',
                    )}
              </CheckboxControl>
            )}
            {config.locationName.visible && (
              <InputControl
                type="text"
                mandatory={config.locationName.mandatory}
                name="locationName"
                id="LocationStep_locationName"
                placeholder={t('location_add.form.locationName.label', 'Point of sale name')}
                title={t('location_add.form.locationName.label', 'Point of sale name')}
              />
            )}
            {values.type === LocationTypeEnum.Physical && (
              <AddressControl config={config} idPrefix="LocationStep" tPrefix="location_add.form" />
            )}
            {(values.type === LocationTypeEnum.Physical ||
              values.type === LocationTypeEnum.Online) &&
              config.website.visible && (
                <InputControl
                  type="text"
                  mandatory={values.type === LocationTypeEnum.Online && config.website.mandatory}
                  name="website"
                  id="LocationStep_website"
                  placeholder={t('location_add.form.website.label', 'Website')}
                  title={t('location_add.form.website.label', 'Website')}
                />
              )}
            {(values.type === LocationTypeEnum.Online ||
              values.type === LocationTypeEnum.Marketplace) &&
              config.email.visible && (
                <InputControl
                  type="email"
                  mandatory={config.email.mandatory}
                  name="email"
                  id="LocationStep_email"
                  placeholder={t('location_add.form.email.label', 'Email')}
                  title={t('location_add.form.email.label', 'Email')}
                />
              )}
            {config.phoneNumber.visible && (
              <PhoneNumberControl
                mandatory={config.phoneNumber.mandatory}
                name="phoneNumber"
                id="LocationStep_phoneNumber"
                title={t('location_add.form.phoneNumber.label', 'Primary phone')}
              />
            )}
            {values.type === LocationTypeEnum.Physical && config.terminalDelayDate.visible && (
              <DateControl
                mandatory={config.terminalDelayDate.mandatory}
                name="terminalDelayDate"
                id="LocationStep_terminalDelayDate"
                placeholder={t('location_add.form.terminalDelayDate.placeholder', 'Delay date')}
                title={t(
                  'location_add.form.terminalDelayDate.label',
                  "If you're taking over a store, please select a date at which we should activate your terminals",
                )}
                minDate={TERMINAL_DELAY_START}
                maxDate={TERMINAL_DELAY_END}
              />
            )}
          </>
        )}
      </VStack>
      {values.type !== '' && (
        <>
          {config.typeOfActivity.visible && (
            <VStack align="stretch" spacing={4}>
              <SectionTitle
                title={t(
                  'location_add.sectionTexts.furtherInformation.header',
                  'Further information',
                )}
              />
              <SelectControl
                mandatory={config.typeOfActivity.mandatory}
                name="typeOfActivity"
                id="LocationStep_typeOfActivity"
                placeholder={t('location_add.form.typeOfActivity.label', 'Activity')}
                title={t('location_add.form.typeOfActivity.label', 'Activity')}
                notFoundText={t('global_texts.labels.noResults', 'No results matching a query')}
                options={activitiesNoParent}
              />
            </VStack>
          )}
          {config.products.visible && (
            <VStack align="stretch" spacing={4}>
              <SectionTitle
                title={t('location_add.sectionTexts.acceptedProducts.header', 'Accepted products')}
              />
              <CheckboxGroupControl
                mandatory={config.products.mandatory}
                name="products"
                id="LocationStep_products"
                options={products}
                renderComponent={ProductCheckbox}
              />
            </VStack>
          )}
          <VStack align="stretch" spacing={4}>
            <SectionTitle
              title={t('location_add.sectionTexts.paymentOptions.header', 'Payment options')}
            />
            <CheckboxGroupControl
              mandatory={config.products.mandatory}
              name="paymentOptions"
              id="LocationStep_paymentOptions"
              options={paymentOptions}
              renderComponent={BePaymentOption}
              // title={t(
              //   'location_add.sectionTexts.paymentOptions.subheader',
              //   'Select one or multiple payment options for your location',
              // )}
            />
          </VStack>
          {config.accountNumber.visible && (
            <VStack align="stretch" spacing={4}>
              <SectionTitle
                title={t(
                  'location_add.sectionTexts.bankingInformation.header',
                  'Banking information',
                )}
              />
              <InputControl
                type="text"
                mandatory={config.accountNumber.mandatory}
                name="accountNumber"
                id="LocationStep_accountNumber"
                placeholder={t('payment_info.form.accountNumber.label', 'Account number')}
                title={t('payment_info.form.accountNumber.label', 'Account number')}
              />
            </VStack>
          )}
          <VStack align="stretch" spacing={4}>
            <SubmitButton
              variant="primaryFilled"
              id="LocationStep__doneButton"
              onClick={isOnboarding ? trackSubmit : undefined}
              width="100%"
            >
              {t('location_add.buttons.save_location', 'Save')}
            </SubmitButton>
            {isSkippable && (
              <Button
                variant="primaryOutlined"
                id="LocationStep__skipButton"
                type="reset"
                onClick={() => toStep(steps.length)}
                width="100%"
                background="semantic.surface.1"
              >
                {t('location_add.buttons.skip', 'Skip')}
              </Button>
            )}
          </VStack>
        </>
      )}
    </>
  );
};

LocationStep.initial = () => ({
  type: '',
  sameCompanyInfo: false,

  // common
  locationName: '',
  phoneNumber: '',
  typeOfActivity: '',
  products: [],
  accountNumber: '',
  website: '',

  // physical
  streetName: '',
  houseNumber: '',
  box: '',
  city: '',
  postalCode: '',
  POS: [],
  paymentOptions: [],
  terminalDelayDate: '',

  // webshop
  email: '',
  terminalProviderOnline: '',
  terminalIdOnline: '',
});

LocationStep.validate =
  ({ config, isPaymentEnabled, terminalProviders }) =>
  async (values: BelgiumOnboardingValues) => {
    const errors: Record<string, any> = {};
    try {
      const isPaymentOptionEnabled = (code: PaymentTypeCodeEnum) =>
        isPaymentEnabled!(code, values.products, values.type);
      const isProductEnabled = (productCode: string) =>
        isPaymentEnabled!(null, [productCode], values.type);
      const isTIDValid = (tid: string) =>
        Boolean(findTerminalProviderByTID(tid, terminalProviders?.tids!));

      const schema = createSchema({
        locationName: config.form.locationName,
        phoneNumber: config.form.phoneNumber,
        typeOfActivity: config.form.typeOfActivity,
        products: config.form.products,
        accountNumber: config.form.accountNumber,
        website: config.form.website,
        paymentOptions: config.form.paymentOptions,

        // physical
        streetName: config.form.streetName,
        houseNumber: config.form.houseNumber,
        box: config.form.box,
        city: config.form.city,
        postalCode: config.form.postalCode,

        // webshop
        email: config.form.email,
        terminalProviderOnline: config.form.terminalProviderOnline,
        terminalIdOnline: config.form.terminalIdOnline,
        terminalDelayDate: config.form.terminalDelayDate,
      }).concat(
        beTerminalsSchema(
          'POS',
          createSchema({
            terminalProvider: config.form.terminalProvider,
            terminalId: config.form.terminalId,
          }),
        ),
      );
      await schema.validate(values, {
        context: {
          isPaymentOptionEnabled,
          isProductEnabled,
          isTIDValid,
        } as BelgiumValidationContext,
        abortEarly: false,
      });
    } catch (error) {
      // abortEarly = false, must access inner
      getValidationErrors(error.inner, errors);
    }

    return errors;
  };

export default LocationStep;
